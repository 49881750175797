<template>
  <div>
    <HeaderMobile :links="links" v-if="isMobile" @logout="logout" />
    <HeaderDesktop :links="links" v-else @logout="logout" />
    <main class="pa-0 ma-0">
      <router-view />
    </main>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapActions } from "vuex";
import { logoutUser } from "@/services/login";
import HeaderMobile from "@/components/header/HeaderMobile.vue";
import HeaderDesktop from "@/components/header/HeaderDesktop.vue";
export default {
  name: "Default",
  components: {
    HeaderMobile,
    HeaderDesktop,
  },
  data: () => ({
    isMobile: false,
    links: [
      {
        title: "Produtos",
        route: "Produtos",
        permission: "product.search",
        children: [],
      },
      {
        title: "Notificações",
        route: "Notificacoes",
        permission: "notification.search",
        children: [],
      },
      {
        title: "Relatórios",
        route: "",
        permission: "statisticProduct.export",
        children: [
          {
            title: "Tempo de Visualização de Produto",
            route: "RelatorioTempoVisualizacaoProduto",
            permission: "statisticProduct.export",
          },
          {
            title: "Quantidade de Visualização de Produto",
            route: "RelatorioQuantidadeVisualizacaoProduto",
            permission: "statisticProduct.export",
          },
          {
            title: "Quantidade de Termos Pesquisados",
            route: "RelatorioQuantidadeTermosPesquisados",
            permission: "statisticTerm.export",
          },
        ],
      },
      {
        title: "Funcionários",
        route: "Funcionarios",
        permission: "employee.search",
        children: [],
      },
      {
        title: "Cadastrados",
        route: "",
        permission: [
          "saleForce.search",
          "anatomyPhysiology.search",
          "pathology.search",
          "specialty.search",
          "laboratory.search",
          "line.search",
          "classification.search",
        ],
        children: [
          {
            title: "Força de Venda",
            route: "ForcaDeVendas",
            permission: "saleForce.search",
          },
          {
            title: "Anatomias e Fisiologias",
            route: "AnatomiasFisiologias",
            permission: "anatomyPhysiology.search",
          },
          {
            title: "Patologias",
            route: "Patologias",
            permission: "pathology.search",
          },
          {
            title: "Especialidades",
            route: "Especialidades",
            permission: "specialty.search",
          },
          {
            title: "Laboratórios",
            route: "Laboratorios",
            permission: "laboratory.search",
          },
          { title: "Linhas", route: "Linhas", permission: "line.search" },
          {
            title: "Classificações",
            route: "Classificacoes",
            permission: "classification.search",
          },
        ],
      },
      {
        title: "Configurações",
        route: "",
        permission: "statisticProduct.export",
        children: [
          {
            title: "Exportar Backup",
            route: "ExportarBackup",
            permission: "statisticProduct.export",
          },
          {
            title: "Importar Backup",
            route: "ImportarBackup",
            permission: "statisticProduct.export",
          },
        ],
      },
    ],
  }),
  created() {
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 768;
    });
    this.isMobile = window.innerWidth <= 768;
  },
  methods: {
    ...mapActions(["setUserData", "setUserLoggedIn"]),
    async logout() {
      await logoutUser()
        .then(() => {
          this.setUserLoggedIn(false);
          this.setUserData([]);
          Cookies.remove("loggedIn");
          Cookies.remove("userData");
          window.localStorage.removeItem("token");
        })
        .then(() => {
          window.location = process.env.VUE_APP_LOGIN;
        });
    },
  },
};
</script>
